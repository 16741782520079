<template>
  <crud-form
    v-if="schema"
    :schema="schema"
    :model="model"
    :path="'tag-item'"
    :form-title="$t('addTagItem{tag}',{tag: model.tag.name})"
    :redirect-route="'TagItemList'"
    :redirect-params="{tagId}"
  />
</template>

<script>
  import CrudForm from '../../components/CrudForm';
  import fieldTagAttributeValue from "./components/FieldTagAttributeValue";
  import fieldRelatedTagItem from "./components/FieldRelatedTagItem";
  import {form} from './forms/tag-item';
  import {schema as initialSchema} from './schema/tagItemStart';
  import {schema as endSchema} from './schema/tagItemEnd';
  import {APIService} from "../../services/api";
  import Vue from "vue";
  import i18n from "@/plugins/i18n";

  Vue.component('fieldTagAttributeValue', fieldTagAttributeValue);
  Vue.component('fieldRelatedTagItem', fieldRelatedTagItem);

  export default {
    name: 'AddTagItem',
    components: {
      CrudForm,
    },
    props: ['tagId', 'parentId'],
    data: () => {
      return {
        schema: null,
        model: form,
        redirectParams: {}
      }
    },
    async beforeMount() {
      if (this.parentId) {
        this.model.parent = {
          id: this.parentId,
        }
      }
      await this.buildSchema(this.tagId);
    },
    methods: {
      async mergeRelatedTagsItemsSchema(tag) {
        const relatedTagAttributesSchema = {fields: []};
        for(const childTag of tag.children) {
          let items = await APIService.get(`tag-item?join=children&join=parent&filter=parent.id||$isnull&sort=id,ASC&join=tag&filter=tag.id||eq||category`);
          items = items.map(tagItem => {
            return {
              header: tagItem.value,
              items: tagItem.children
            }
          });
          let relatedSchemaElement = {
            type: "accordion",
            model: "relatedTagItems.relatedTagItem",
            checked: true,
            required: true,
            items,
            labelField: i18n.t("value"),
            valueField: "id",
          };
          if (relatedSchemaElement) {
            relatedTagAttributesSchema.fields.push(relatedSchemaElement);
          }
          let schemaElement = {
            type: "checkboxList",
            label: childTag.value,
            model: "relatedTagItems.relatedTagItem",
            required: false,
            items: APIService.get(`tag-item?sort=id,ASC&join=tag&filter=tag.id||eq||${childTag.id}`),
            labelField: i18n.t("value"),
            valueField: "id",
          };
          if (schemaElement) {
            relatedTagAttributesSchema.fields.push(schemaElement);
          }
        }
        return relatedTagAttributesSchema;
      },
      async buildSchema(tagId) {
        try {
          const tag = await APIService.get(`tag/${tagId}?join=attributes&join=attributes.enums&join=children`);
          this.model.tag = tag;
          const extraAttributesSchema = {
            fields: [
              {
                type: "tagAttributeValue",
                model: 'values',
                required: true,
                attributes: tag.attributes,
              }
            ]
          };

          let relatedTagAttributesSchema = null;
          if (tag.token === 'producer') {
            relatedTagAttributesSchema = {
              fields: [
                {
                  type: "relatedTagItem",
                  model: 'relatedTagItems',
                  required: true,
                  tags: tag.children,
                }
              ]
            };
          }

          let subtype = 1;
          if (tag.token === 'category') {
            subtype = 2;
          }
          if (tag.token === 'attribute') {
            subtype = 3;
          }

          const schema = Object.assign({}, {
            fields: [{
              type: "imageUpload",
              label: "images",
              model: "images",
              required: false,
              placeholder: "images",
              imageType: "TagItem",
              suffix: "normal",
              imageSubtype: subtype,
            }]
          });
          schema.fields = schema.fields.concat(initialSchema.fields);
          schema.fields = schema.fields.concat(extraAttributesSchema.fields);
          if (relatedTagAttributesSchema) {
            schema.fields = schema.fields.concat(relatedTagAttributesSchema.fields);
          }
          schema.fields = schema.fields.concat(endSchema.fields);

          this.schema = schema;

        } catch (e) {
          console.error(e);
        }
      }
    },
  }
</script>

